import React, { useState, useEffect } from 'react';
import { Row, Col, Tabs, Card } from 'antd';
import Masonry from 'react-masonry-component';
import Lightbox from 'lightbox-react';

import Layout from '../components/layout';
import SEO from "../components/seo"

const categories = ['Custom Bikes', 'Sportster', 'Dyna', 'Softail', 'Touring', 'V-Rod', 'Autres'];

const bikes = [
	[{
		name: 'H-D Panhead 1948',
		description: <><p>Un projet qui touche à sa fin.</p><p>Une restauration complète d'un panhead de 1948.</p><p>Des dizaines d'heures pour restaurer et modifier ce magnifique Panhead.</p><ul><li>De nombreuses pièces faites mains</li><li>Modifications de pièces aftermarkts</li><li>Remplacement quasi-complète de la visserie laitonner ou chromer.</li><li>Réfection complète du système électrique avec l'allumage et la génératrice d'origine modifié en 12V</li><li>Peinture/thermolaquage des pièces</li><li>Remise à neuf de pièces d'origine (roues, Freins,embrayage,etc.)</li><li>Moteur refait à neuf avec cylindres, culasses, bas moteur d'origine avec kit 1200 d'époque effectuer par Atomik Cycle</li><li>Boite à vitesse neuve</li><li>Modification de la ligne d'échappement d'origine</li><li>Montage d'un carburateur S.U</li></ul></>,
		src: '/customs/panhead',
		images: [
			'H-D_Panhead_1948_001.jpg',
			'H-D_Panhead_1948_002.jpg',
			'H-D_Panhead_1948_003.jpg',
			'H-D_Panhead_1948_004.jpg',
			'H-D_Panhead_1948_005.jpg',
			'H-D_Panhead_1948_006.jpg',
			'H-D_Panhead_1948_007.jpg',
			'H-D_Panhead_1948_008.jpg',
			'H-D_Panhead_1948_009.jpg',
			'H-D_Panhead_1948_010.jpg',
			'H-D_Panhead_1948_011.jpg'
		]
	}, {
		name: 'The Digger',
		description: <><p>Une création sur mesure by Black Way Motorcycles</p><p>Moto unique et homologuée</p></>,
		src: '/customs/digger',
		images: [
			'The_Digger001.jpg',
			'The_Digger002.jpg'
		]
	}, {
		name: 'The Cobalt',
		description: <><p>Cette moto a été réalisée dans notre atelier par Laurent Dutruel.</p></>,
		src: '/customs/cobalt',
		images: [
			'cobalt_001.jpg',
			'cobalt_002.jpg',
			'cobalt_003.jpg',
			'cobalt_004.jpg',
			'cobalt_005.jpg',
			'cobalt_006.jpg',
			'cobalt_007.jpg',
			'cobalt_008.jpg',
			'cobalt_009.jpg',
			'cobalt_010.jpg',
			'cobalt_011.jpg',
			'cobalt_012.jpg',
			'cobalt_013.jpg',
			'cobalt_014.jpg',
			'cobalt_015.jpg'
		]
	}, {
		name: 'Daddy\'s Toy',
		description: <><p>Moto sur mesure homologuée. Moteur S&S 1700 cm3. Suspension pneumatique, pneu arrière 280 mm, compteur intégré dans le filtre à air + nombreux détails. Peinture par MARTY DESIGN.</p></>,
		src: '/customs/daddystoy',
		images: [
			'daddy-s-toy-11.jpg',
			'daddy-s-toy-13.jpg',
			'daddy-s-toy-14.jpg',
			'daddy-s-toy-15.jpg',
			'daddy-s-toy-16.jpg',
		]
	}, {
		name: 'El Corojo',
		description: <><p>Kit Bike CUSTOM CHROME homologué.</p><p>1800 cm3 de pur bonheur.</p><p>En hommage à une grande marque de cigares.</p><p>Peinture Marty Design</p></>,
		src: '/customs/elcorojo',
		images: [
			'el-corojo-1.jpg',
			'el-corojo-2.jpg',
			'el-corojo-3.jpg',
			'el-corojo-4.jpg',
			'el-corojo-5.jpg',
			'el-corojo-6.jpg',
			'el-corojo-7.jpg',
			'el-corojo-8.jpg'
		]
	}],

	[{
		name: 'H-D Forty-Eight Grenat',
		description: <><p>Un Forty-eight qui à passer sur le billard pour un arrière court, amortisseur court, clignotants avant et arrière</p><p>Merci au client pour sa confiance</p></>,
		src: '/sportster/forty_eight_grenat',
		images: [
			'Forty_Eight_Grenat_001.jpg',
			'Forty_Eight_Grenat_002.jpg',
			'Forty_Eight_Grenat_003.jpg',
			'Forty_Eight_Grenat_004.jpg',
			'Forty_Eight_Grenat_005.jpg'
		]
	}, {
		name: 'H-D Sporster Grey & Black',
		description: <><p>Une 48 avec une peinture metal flack gris fait par Vince Pinstriping.</p><p>Quelques modifications de choix qui lui donne un look bobber by Black Way</p><p><strong>Modifications</strong></p> <ul><li>Peinture réservoir + réhaussement</li><li>Compteur motogadget</li> <li>Guidon + supression des commodos et commande K-Tech</li><li>Bande thermique sur les collecteurs échappement</li> <li>Délocalisation bobine, contacteur à clé, molette valve échappement</li><li>Passage de la torche électrique sous le réservoir</li> <li>etc</li></ul></>,
		src: '/sportster/grey_black',
		images: [
			'H-D_Sporster_Grey_Black_001.jpg',
			'H-D_Sporster_Grey_Black_002.jpg',
			'H-D_Sporster_Grey_Black_003.jpg',
			'H-D_Sporster_Grey_Black_004.jpg',
			'H-D_Sporster_Grey_Black_005.jpg',
			'H-D_Sporster_Grey_Black_006.jpg',
			'H-D_Sporster_Grey_Black_007.jpg',
			'H-D_Sporster_Grey_Black_008.jpg',
			'H-D_Sporster_Grey_Black_009.jpg'
		]
	}, {
		name: 'H-D Iron Spéciale',
		description: <><p>Une Iron aux allures d'origine, mais qui a subi des transformations bien spécifiques selon les souhaits de la cliente.</p><p>Notre cliente ayant un handicape à la jambe gauche, il fallait trouver un système pour que les vitesses soient passées avec le pied-droit. Cette modification trop compliquée et qui aurait gâché la silhouette de cette moto a laissé place à un système de sélection de vitesse "automatique" .</p><p>Par simple pression sur deux boutons situés au guidon, cela actionne un piston électrique relié au sélecteur qui le pousse (monté les rapports) ou qui le tire (descendre les rapports) le tout équipé d'un shifter.</p><p>Puis après avoir trouvé cette solution, il a fallu trouver un système pour que la béquille puisse être mise ou enlever, ne pouvant pas la pousser avec le pied, la béquille a été modifiée pour que la cliente puisse la mettre avec la main à l'aide d'un aimant rétractable.</p><p>En plus de trouver ces solutions, il a fallu rabaisser la moto à l'avant et l'arrière pour que la cliente soit à l'aise quand la moto est à l'arrêt. Une selle Lepera pour le confort, puis décaler le cale-pied gauche de 4 cm pour que sa jambe soit installée confortablement.</p><p>Des modifications utiles et pas communes, mais qui on permit à notre cliente de pouvoir enfin roulé avec une HD comme elle le souhaitait.</p><p>Merci à elle pour la confiance et bonne route.</p></>,
		src: '/sportster/hd_iron_speciale',
		images: [
			'h-d_iron_speciale_001.jpg',
			'h-d_iron_speciale_002.jpg'
		]
	}, {
		name: 'HD 48 Black Mat',
		description: <><p>Une jolie forty eight , sobre avec sa robe noire. pleins de petites modifications qui font une moto sobre avec un look bobber bien sympathique.</p><p>Garde boue arrière légerement raccourci , feu arrière style "Batte" , clignotants avant et arrière changer et repositioner , support plaque latérale , pot bsl avec stage 1, peinture complète noire, etc...</p></>,
		src: '/sportster/hd_48_black_mat',
		images: [
			'hd_48_black_mat_001.jpg',
			'hd_48_black_mat_002.jpg',
			'hd_48_black_mat_003.jpg',
			'hd_48_black_mat_004.jpg',
			'hd_48_black_mat_005.jpg',
			'hd_48_black_mat_006.jpg'
		]
	}, {
		name: 'Harley-Davidson MESCAL',
		description: <></>,
		src: '/sportster/mescal',
		images: [
			'mescal_001.jpg',
			'mescal_002.jpg',
			'mescal_003.jpg',
			'mescal_004.jpg',
			'mescal_005.jpg',
			'mescal_006.jpg',
			'mescal_007.jpg',
			'mescal_008.jpg',
			'mescal_009.jpg',
			'mescal_010.jpg',
			'mescal_011.jpg',
			'mescal_012.jpg'
		]
	}, {
		name: 'Assercool',
		description: <><p>Difficile d'indiquer tout ce qui a été modifié sur ce Sportster de 1992.</p><p>En résumé, échappement électronique, Motogadget, DUB, peinture noire vermiculée...</p><p>Comme d'habitude, tout est homologué.</p></>,
		src: '/sportster/assercool',
		images: [
			'assercool1.jpg',
			'assercool2.jpg',
			'assercool3.jpg',
			'assercool4.jpg',
			'assercool5.jpg',
			'assercool7.jpg',
			'assercool8.jpg',
			'assercool9.jpg',
			'assercool11.jpg',
			'assercool22.jpg',
			'assercool33.jpg',
			'assercool44.jpg'
		]
	}, {
		name: 'Harley-Davidson Sportster Springer',
		description: <><p>Harley-Davidson Sportster avec vraie fourche Springer homologuée.</p><p>Fabrication américaine. Pas de Paughco ou de made in Taïwan !!!</p></>,
		src: '/sportster/sportster_springer',
		images: [
			'sportster_springer_001.jpg',
			'sportster_springer_002.jpg',
			'sportster_springer_003.jpg',
			'sportster_springer_004.jpg',
			'sportster_springer_005.jpg',
			'sportster_springer_006.jpg',
			'sportster_springer_007.jpg',
			'sportster_springer_008.jpg'
		]
	}, {
		name: 'Gasoline',
		description: <><p>Une Harley comme on aime avec un look simple.</p><ul><li>peinture par Stéphanie Dutruel de l'atelier Allegoria</li><li>échappement Kesstech</li><li>selle Easyriders</li><li>garde-boue TB</li><li>pneus old school</li><li>poignées PM</li><li>filtre Clear</li><li>guidon mini Ape</li></ul></>,
		src: '/sportster/gasoline',
		images: [
			'gasoline_001.jpg',
			'gasoline_002.jpg',
			'gasoline_003.jpg',
			'gasoline_004.jpg',
			'gasoline_005.jpg',
			'gasoline_006.jpg',
			'gasoline_007.jpg',
			'gasoline_008.jpg'
		]
	}, {
		name: 'FORCE & HONNEUR',
		description: <><p>Propriétaire de ce Nightster avec 4 titres de championne du monde ISKA 1997-1998 et 2001.</p><p>WAKO 2004, voici un bel hommage à la boxe thaï et au kick boxing. Peinture réalisée par notre artiste Stéphanie Bonhomme. Sellerie Christophe Seewer, Artisan du Cuir.</p></>,
		src: '/sportster/force_honneur',
		images: [
			'force_honneur_001.jpg',
			'force_honneur_002.jpg',
			'force_honneur_003.jpg',
			'force_honneur_004.jpg',
			'force_honneur_005.jpg',
			'force_honneur_006.jpg',
			'force_honneur_007.jpg',
			'force_honneur_008.jpg',
			'force_honneur_009.jpg',
			'force_honneur_010.jpg',
			'force_honneur_011.jpg',
			'force_honneur_012.jpg',
			'force_honneur_013.jpg',
			'force_honneur_014.jpg',
			'force_honneur_015.jpg'
		]
	}, {
		name: 'Metal & Brass 48',
		description: <><p>Quelques détails et accessoires sympas sur cette Harley-Davidson Sportster Forty Eight.</p></>,
		src: '/sportster/metal_brass',
		images: [
			'metal_brass_001.jpg',
			'metal_brass_002.jpg',
			'metal_brass_003.jpg',
			'metal_brass_004.jpg',
			'metal_brass_005.jpg',
			'metal_brass_006.jpg'
		]
	}],

	[{
		name: 'H-D Dyna Sturgis',
		description: <><p>Changement de proprio = Changement de look</p><p><strong>Les transfos :</strong></p><ul><li>Mikuni HSR 42</li><li>Filtre à air Performance Machine</li><li>Echappement Supertrapp</li><li>Suspension arrière basse de chezProgressive Suspension</li><li>Guidon drag bar LSL</li><li>Primaire ouvert Ultima</li><li>Commandes au guidon Performanche machine + durites de frein type "aviation"</li><li>Frein avant 6 pistons de chez Performance machine</li><li>selle solo Lepera</li><li>Garde boue arrière court avec feu led</li><li>supression des clignotants</li><li>Garde boue avant after market modifié </li><li>Restauration du bac à batterie,cache bobine, et divers éléments de carrosserie thermolaquer en noir</li></ul></>,
		src: '/dyna/sturgis',
		images: [
			'sturgis_001.jpg',
			'sturgis_002.jpg',
			'sturgis_003.jpg',
			'sturgis_004.jpg',
			'sturgis_005.jpg',
			'sturgis_006.jpg'
		]
	}],

	[{
		name: 'H-D Softail Slim Bellacrye',
		description: <><p>Un Softail Slim tout neuf modifié par nos soins</p><p><strong>Les modifications effectuées :</strong></p><ul><li>Garde boue avant et arrière type "chicanos"</li><li>Sisibar avec support de dosseret rajouté par nos soins</li><li>Kit feux auxiliaires HD + clignotants avant led's HD</li><li>Guidon Beach Bar chrome + petit rétroviseurs</li><li>Ligne d'échappement Redthunder</li><li>Clignotants arrière Kellerman Alto </li><li>Feux arrière avec support plaque integré</li></ul><p>Peinture : Jesse Design </p><p>Pinstriping : Seb gaidin</p><p>Gravage : Thierry de Art's Kustom</p><p>Merci à Régis pour sa confiance !</p></>,
		src: '/softail/slim_bellacrye',
		images: [
			'slim_bellacrye_001.jpg',
			'slim_bellacrye_002.jpg',
			'slim_bellacrye_004.jpg',
			'slim_bellacrye_005.jpg',
			'slim_bellacrye_006.jpg',
			'slim_bellacrye_007.jpg',
			'slim_bellacrye_008.jpg',
			'slim_bellacrye_009.jpg',
			'slim_bellacrye_010.jpg',
			'slim_bellacrye_011.jpg',
			'slim_bellacrye_012.jpg',
			'slim_bellacrye_014.jpg',
			'slim_bellacrye_015.jpg',
			'slim_bellacrye_016.jpg',
			'slim_bellacrye_017.jpg'
		]
	}, {
		name: 'H-D Softail Springer Brown',
		description: <><p>Un Springer 1340 rajeuni par nos soins</p><ul><li>Peinture complète par notre ami Jesse Design Paint Shop</li><li>Remplacement pièces haut moteur</li><li>Electricité complète neuve et moderniser (allumage électronique , boitier de contrôle motogadget)</li><li>Montage guidon Dragbar + commandes PM</li><li>Divers accessoires</li></ul><p>Tout cela nous donne un springer sobre et classe et remis au gout du jour.</p><p>La suite au prochain épisode</p><p>Merci à David pour la confiance</p></>,
		src: '/softail/springer_brown',
		images: [
			'springer_brown_001.jpg',
			'springer_brown_002.jpg',
			'springer_brown_003.jpg',
			'springer_brown_004.jpg',
			'springer_brown_005.jpg',
			'springer_brown_006.jpg'
		]
	}, {
		name: 'H-D Softail Greenbones',
		description: <><p>À la base s'était juste un changement de jantes...</p><p>Puis on s'est retrouvé avec des pièces du surplus militaire apporter par le client et au final une envie de couleur militaire et voilà la greenbones est née....</p><p>Notez l'utilité de la caisse à munitions pour aller chercher les pizzas durant le mondial pour les amateurs de foot</p><p>Merci au client pour ce projet pas comme les autres et à sa vision du custom bien personnel</p><p>Le tout 100% homologué et visite du jour (Hormis les feux auxiliaires)</p></>,
		src: '/softail/greenbones',
		images: [
			'softail_greenbones_001.jpg',
			'softail_greenbones_002.jpg',
			'softail_greenbones_003.jpg',
			'softail_greenbones_004.jpg',
			'softail_greenbones_005.jpg',
			'softail_greenbones_006.jpg'
		]
	}, {
		name: 'H-D Softail Springer Blouge',
		description: <><p>Un Springer 1340 qui revient de loin... plus de 100'000 Kms, rouillé de partout.... Après un démontage complet et  plusieurs mois de travail voici un bobber Springer By Black Way !</p><ul><li>Partie cycle démonté entierement , sablé et thermolaqué</li><li>Moteur révisé</li><li>Electricité complète neuve et moderniser (allumage électronique , boitier de contrôle motogadget)</li><li>Springer d'origine réstaurer</li><li>Echappement BSL</li><li>Dashboard fait maison avec installation d'un compteur motogadget</li><li>Suspension pneumatique arrière</li><li>Peinture par Jesse Design</li><li>Pinstriping Par Seb Gaidin</li></ul><p>Merci au client pour sa confiance.</p></>,
		src: '/softail/springer_blouge',
		images: [
			'springer_blouge_001.jpg',
			'springer_blouge_002.jpg',
			'springer_blouge_003.jpg'
		]
	}, {
		name: 'HD Softail Springer Water Drop',
		description: <><p>Une bonne cure de liposuccion et de botox sur cette base de Softail 1340 Springer de 1990 avec 100'000 KMS au compteur.</p><p>Pas mal d'heures de travail pour restaurer et customiser cette moto de fond en comble.</p><ul><li>Thermolaquage de nombreuses pièces (cadre, bras oscillant, fourche, etc.)</li><li>Contrôle complet du moteur</li><li>Nombreuses pièces custom (bac à huile, phare, roues, garde boue, embrayage, etc.)</li><li>Création de pièces sur mesures</li><li>Pinstriping par Gadin Seb</li></ul><p>Tout cela donne une moto quasi sortie de boite et homologuée.</p></>,
		src: '/softail/springer_water_drop',
		images: [
			'springer_water_drop_001.jpg',
			'springer_water_drop_002.jpg',
			'springer_water_drop_003.jpg',
			'springer_water_drop_004.jpg',
			'springer_water_drop_005.jpg',
			'springer_water_drop_006.jpg',
			'springer_water_drop_007.jpg',
			'springer_water_drop_008.jpg',
			'springer_water_drop_009.jpg'
		]
	}, {
		name: 'H-D Candy Bitch',
		description: <><p>Une base H-D Softail 1340 de 1994 avec un démontage complet châssis et moteur, arrière sur mesure , réservoir de sportster, des pièces RSD, support plaque fait maison, etc....</p><p>Des dizaines d'heures de travail pour au final avoir un 1340 quasi sorti de boite au look bobber comme on aime !</p></>,
		src: '/softail/candy_bitch',
		images: [
			'candy_bitch_001.jpg',
			'candy_bitch_002.jpg',
			'candy_bitch_003.jpg'
		]
	}, {
		name: 'H-D Springer Brush',
		description: <><p>Un look épuré avec des accessoires de qualité et un brossage des parties en aluminium.</p></>,
		src: '/softail/springer_brush',
		images: [
			'springer_brush_001.jpg',
			'springer_brush_002.jpg',
			'springer_brush_003.jpg',
			'springer_brush_004.jpg',
			'springer_brush_005.jpg',
			'springer_brush_006.jpg',
			'springer_brush_007.jpg',
			'springer_brush_008.jpg',
			'springer_brush_009.jpg',
			'springer_brush_010.jpg'
		]
	}, {
		name: 'H-D Springer 1990',
		description: <><p>Un ligne épurée dans le plus pur style old school.</p></>,
		src: '/softail/springer_1990',
		images: [
			'springer_1990_001.jpg',
			'springer_1990_002.jpg',
			'springer_1990_003.jpg',
			'springer_1990_004.jpg',
			'springer_1990_005.jpg',
			'springer_1990_006.jpg'
		]
	}, {
		name: 'H-D Breakout Carpe Koi',
		description: <><p>Harley-Davidson Breakout Carpe Koi</p><p>Peinture par SEBBES Custom Paint</p><p>Sellerie par LUGON Garnisseur</p><p>Tout le reste effectué dans notre atelier.</p></>,
		src: '/softail/carpe_koi',
		images: [
			'carpe_koi_001.jpg',
			'carpe_koi_002.jpg',
			'carpe_koi_003.jpg',
			'carpe_koi_004.jpg',
			'carpe_koi_005.jpg'
		]
	}, {
		name: 'Post Tenebras Lux',
		description: <><p>Une Softail Breakout au thème de notre beau canton. Made In Genève !</p></>,
		src: '/softail/post_tenebras_lux',
		images: [
			'post_tenebras_lux_001.jpg',
			'post_tenebras_lux_002.jpg',
			'post_tenebras_lux_003.jpg',
			'post_tenebras_lux_004.jpg',
			'post_tenebras_lux_005.jpg',
			'post_tenebras_lux_006.jpg',
			'post_tenebras_lux_007.jpg',
			'post_tenebras_lux_008.jpg'
		]
	}, {
		name: 'H-D Softail Unforgiven Sinner',
		description: <><p>Harley-Davidson Softail, 1992, modifié dans notre atelier.</p></>,
		src: '/softail/unforgiven_sinner',
		images: [
			'unforgiven_sinner_001.jpg',
			'unforgiven_sinner_002.jpg',
			'unforgiven_sinner_003.jpg',
			'unforgiven_sinner_004.jpg',
			'unforgiven_sinner_005.jpg',
			'unforgiven_sinner_006.jpg',
			'unforgiven_sinner_007.jpg',
			'unforgiven_sinner_008.jpg',
			'unforgiven_sinner_009.jpg',
			'unforgiven_sinner_010.jpg',
			'unforgiven_sinner_011.jpg',
			'unforgiven_sinner_012.jpg'
		]
	}, {
		name: 'Bobber la Verte',
		description: <><p>La moto de référence pour le confort de sa selle et son réservoir de 28 lt.</p></>,
		src: '/softail/bobber_la_verte',
		images: [
			'bobber-la-verte-1.jpg',
			'bobber-la-verte-2.jpg',
			'bobber-la-verte-3.jpg',
			'bobber-la-verte-4.jpg',
			'bobber-la-verte-5.jpg',
			'bobber-la-verte-6.jpg'
		]
	}],

	[{
		name: 'Urban Bagger',
		description: <><p>Un travail de longue haleine pour ce Bagger sur une base de 1987.</p><p>Peinture par SEBBES Custom Paint à Saxon.</p><p>Sellerie par LUGON Garnisseur à Martigny.</p><p>Le reste par nos soins.</p></>,
		src: '/touring/urban_bagger',
		images: [
			'urban_bagger_001.jpg',
			'urban_bagger_002.jpg',
			'urban_bagger_003.jpg',
			'urban_bagger_004.jpg',
			'urban_bagger_005.jpg',
			'urban_bagger_006.jpg',
			'urban_bagger_007.jpg',
			'urban_bagger_008.jpg'
		]
	}, {
		name: 'From Hell',
		description: <><p>H-D Road King, kit carbu S&S, compteur et bouchon d'essence incrustés dans le réservoir, feux led et une superbe peinture de JMS Création.</p></>,
		src: '/touring/from_hell',
		images: [
			'from_hell_001.jpg',
			'from_hell_002.jpg',
			'from_hell_003.jpg',
			'from_hell_004.jpg',
			'from_hell_006.jpg',
			'from_hell_007.jpg',
			'from_hell_008.jpg',
			'from_hell_009.jpg',
			'from_hell_010.jpg',
			'from_hell_011.jpg'
		]
	}, {
		name: 'CRIOLLO',
		description: <><p><strong>BEST OF SHOW - Verbier Bike Fest 2012</strong></p><p>Sur la base d'une Road King de 2002, voici ce que l'on a réalisé.</p><ul><li>kit té de fourche PAUL YAFFE</li><li>stretch du cadre 7°</li><li>roue PY 23"</li><li>frein PM doré</li><li>garde boue arrière CHUPA</li><li>filtre RSD</li><li>échappements SE</li><li>paint by MARTY DESIGN</li></ul></>,
		src: '/touring/criollo',
		images: [
			'criollo-1.jpg',
			'criollo-2.jpg',
			'criollo-3.jpg',
			'criollo-4.jpg',
			'criollo-6.jpg',
			'criollo-7.jpg',
			'criollo-8.jpg'
		]
	}],

	[{
		name: 'H-D V-Rod Indika',
		description: <><p>Une V-Rod de 2013 sorti de notre atelier après une longue attente hivernale !</p><p><strong>Modifications :</strong></p><ul><li>Kit carrosserie + peinture selon souhait du client</li><li>Remplacement du guidon et riser et délocalisation du compteur dans le cache de la boite à air pour alléger le guidon</li><li>Selle sur-mesure</li><li>Support plaque latérale</li><li>Thermolaquage des leviers, commande aux pieds, etc.</li><li>Clignotant arrière Rizoma 3en1</li><li>Clignotant avant plat noir fumé</li><li>Ligne d'échappement Jekill&Hyde</li><li>Suspension arrière pneumatique (Legend Air)</li></ul><p>Le tout homologué, une V-Rod sobre et efficace comme le souhaitait le propriétaire</p><p>Bonne route à lui avec sa nouvelle HD By Black Way Motorcycles</p></>,
		src: '/vrod/hd_v_rod_indika',
		images: [
			'H_D_V-Rod_Indika_001.jpg',
			'H_D_V-Rod_Indika_002.jpg',
			'H_D_V-Rod_Indika_003.jpg',
			'H_D_V-Rod_Indika_004.jpg',
			'H_D_V-Rod_Indika_005.jpg',
			'H_D_V-Rod_Indika_006.jpg',
			'H_D_V-Rod_Indika_007.jpg',
			'H_D_V-Rod_Indika_008.jpg'
		]
	}, {
		name: 'H-D V-Rod Grey & Green',
		description: <><p>Une V-Rod "ancienne" génération qui à subi une sacrée cure de rajeunissement, kit pneu large , échappement , jantes, kit carroserie etc....</p><p>Une peinture de Jesse Design et cela nous donne une V-Rod bodybuilder et 100% homologuée by Black Way Motorcycles.</p></>,
		src: '/vrod/hd_v_rod_grey_green',
		images: [
			'H-D_rod_grey_green_001.jpg',
			'H-D_rod_grey_green_002.jpg',
			'H-D_rod_grey_green_003.jpg',
			'H-D_rod_grey_green_004.jpg',
			'H-D_rod_grey_green_005.jpg',
			'H-D_rod_grey_green_006.jpg',
			'H-D_rod_grey_green_007.jpg'
		]
	}, {
		name: 'H-D Night Rod Honesta Mors',
		description: <><p>Une Harley-Davidson compacte avec un look sobre et des accessoires de bon goût.</p><p>Le tout modifé dans notre atelier, homologué et prêt à rouler.</p></>,
		src: '/vrod/night_rod_honesta_mors',
		images: [
			'night_rod_honesta_mors_001.jpg',
			'night_rod_honesta_mors_002.jpg',
			'night_rod_honesta_mors_003.jpg',
			'night_rod_honesta_mors_004.jpg',
			'night_rod_honesta_mors_005.jpg',
			'night_rod_honesta_mors_006.jpg'
		]
	}, {
		name: 'Truth Seeker',
		description: <><p>Cette Harley-Davidson Night Rod est homologué avec les accessoires suivants :</p><ul><li>roue RC 300x18 à l'arrière et roue de 21" à l'avant.</li><li>carrosserie NLC</li><li>garde boue avant Black Way</li><li>guidon Fehling</li><li>suspension Progressive</li><li>selle sur mesure skaï</li><li>feux et clignoteurs CCE</li><li>peinture par Stéphanie Dutruel de L'Atelier ALLEGORIA</li></ul></>,
		src: '/vrod/truth_seeker',
		images: [
			'truth_seeker_001.jpg',
			'truth_seeker_002.jpg',
			'truth_seeker_003.jpg',
			'truth_seeker_004.jpg',
			'truth_seeker_005.jpg',
			'truth_seeker_006.jpg',
			'truth_seeker_007.jpg',
			'truth_seeker_008.jpg',
			'truth_seeker_009.jpg'
		]
	}],

	[{
		name: 'BMW R Nine T Cafe Racer',
		description: <><p><strong>Quelques modifications sur cette Nine-T</strong></p><ul><li>Coque arrière + selle</li><li>Commande reculée</li><li>Compteur motogadget</li><li>Peinture complète</li><li>Echappement Akrapovic</li><li>Filtre à air</li><li>Plein de petits détails</li></ul></>,
		src: '/autres/bmw_r_nine_t_cafe_racer',
		images: [
			'bmw_r_nine_t_cafe_racer_001.jpg',
			'bmw_r_nine_t_cafe_racer_002.jpg',
			'bmw_r_nine_t_cafe_racer_003.jpg',
			'bmw_r_nine_t_cafe_racer_004.jpg',
			'bmw_r_nine_t_cafe_racer_005.jpg',
			'bmw_r_nine_t_cafe_racer_006.jpg',
			'bmw_r_nine_t_cafe_racer_007.jpg',
			'bmw_r_nine_t_cafe_racer_008.jpg',
			'bmw_r_nine_t_cafe_racer_009.jpg',
			'bmw_r_nine_t_cafe_racer_010.jpg',
			'bmw_r_nine_t_cafe_racer_011.jpg',
			'bmw_r_nine_t_cafe_racer_012.jpg'
		]
	}, {
		name: 'Zero Engineering Typ9',
		description: <><p>Une bonne base et quelques modifications subtiles qui sublime cette Zéro Engineering</p></>,
		src: '/autres/zero_engineering_typ9',
		images: [
			'zero_engineering_typ9_001.jpg',
			'zero_engineering_typ9_002.jpg',
			'zero_engineering_typ9_003.jpg',
			'zero_engineering_typ9_004.jpg',
			'zero_engineering_typ9_005.jpg',
			'zero_engineering_typ9_006.jpg',
			'zero_engineering_typ9_007.jpg',
			'zero_engineering_typ9_008.jpg'
		]
	}, {
		name: 'Zero Engineering Light Blue',
		description: <><p>Homologuée en Suisse. Quelques pièces aftermarket et un support plaque maison pour cette Zéro Engineering.</p></>,
		src: '/autres/zero_engineering_light_blue',
		images: [
			'zero_engineering_light_blue_001.jpg',
			'zero_engineering_light_blue_002.jpg',
			'zero_engineering_light_blue_003.jpg',
			'zero_engineering_light_blue_004.jpg',
			'zero_engineering_light_blue_005.jpg',
			'zero_engineering_light_blue_006.jpg'
		]
	}, {
		name: 'Triumph Speed Triple',
		description: <><p>Un look Café Racer sur cette Triumph Speed Triple de 2009</p><p>Carrosserie sur mesure en aluminium brossé réalisée dans notre atelier.</p></>,
		src: '/autres/triumph_speed_triple',
		images: [
			'speed-triple11.jpg',
			'speed-triple12.jpg',
			'speed-triple13.jpg',
			'speed-triple14.jpg',
			'speed-triple15.jpg',
			'speed-triple16.jpg',
			'speed-triple17.jpg',
			'speed-triple18.jpg',
			'speed-triple19.jpg'
		]
	}, {
		name: 'MV Agusta Brutale',
		description: <><p>MV Agusta Brutale 990 R modifiée sur mesure :</p><ul><li>berceau de cadre arrière alu</li><li>échappement sur mesure</li><li>guidon, rétroviseurs, etc.</li><li>peinture JEFF Color</li><li>sellerie LUGON</li></ul></>,
		src: '/autres/mv_agusta',
		images: [
			'mv_agusta_001.jpg',
			'mv_agusta_002.jpg',
			'mv_agusta_003.jpg',
			'mv_agusta_004.jpg',
			'mv_agusta_005.jpg',
			'mv_agusta_006.jpg',
			'mv_agusta_007.jpg'
		]
	}, {
		name: 'Kawazaki Cafe Racer',
		description: <><p>Garde boue avant et arrière court, clignotants micro, feu arrière à LED + quelques détails. Et le tout homologué bien sûr.</p></>,
		src: '/autres/kawasaki_cafe_racer',
		images: [
			'kawasaki_cafe_racer_001.jpg',
			'kawasaki_cafe_racer_002.jpg',
			'kawasaki_cafe_racer_003.jpg',
			'kawasaki_cafe_racer_004.jpg',
			'kawasaki_cafe_racer_005.jpg',
			'kawasaki_cafe_racer_006.jpg',
			'kawasaki_cafe_racer_007.jpg',
			'kawasaki_cafe_racer_008.jpg',
			'kawasaki_cafe_racer_009.jpg',
			'kawasaki_cafe_racer_010.jpg'
		]
	}]
];

const CustomGallery = ({ category, images }) => {
	const [ tabPos, setTabPos ] = useState('top');

	const updateTabPos = () => {
		if (window)
			window.innerWidth < 768 ? setTabPos('top') : setTabPos('left');
	}

	const [ pictures, setPictures ] = useState([]);
	const [ fullscreenGallery, setFullscreenGallery ] = useState(false);
	const [ fullscreenIndex, setFullscreenIndex ] = useState(null);

	const toggleFullscreenGallery = (index = null) => {
		setFullscreenGallery(!fullscreenGallery);
		setFullscreenIndex(index);
	}

	const setLightboxPictures = (index) => {
		let pics = [];

		bikes[category][index].images.forEach(image => {
			pics.push(`${bikes[category][index].src}/${image}`);
		});

		setPictures(pics);
	}

	useEffect(() => {
		if (pictures.length === 0)
			setLightboxPictures(0);

		updateTabPos();

		window.addEventListener('resize', updateTabPos, false);
		return () => window.removeEventListener('resize', updateTabPos, false);
	});

	return (
		<>
			<Tabs defaultActiveKey="0" tabPosition={tabPos}>
				{bikes[category].map((item, key) => (
					<Tabs.TabPane tab={item.name} key={key} onChange={tab => setLightboxPictures(tab)}>
						<h3 style={{  padding: '20px 30px', textAlign: 'left', fontSize: '1.6rem' }}>{item.name}</h3>
						<Row type="flex" gutter={16}>
							<Col xs={24} lg={12} style={{ textAlign: 'left' }}>
								<div style={{ padding: '0 30px' }}>{item.description}</div>
							</Col>
							<Col xs={24} lg={12}>
								<Masonry>
									{item.images.map((image, key) => (
										<Col key={key} xs={12} lg={8}>
											<Card size="small" onClick={() => toggleFullscreenGallery(key)} hoverable>
												<img src={`${item.src}/small/${image}`} alt={item.name} style={{ maxWidth: '100%' }} />
											</Card>
										</Col>
									))}
								</Masonry>
							</Col>
						</Row>
					</Tabs.TabPane>
				))}
			</Tabs>

			{
				fullscreenGallery && (
					<Lightbox
						mainSrc={pictures[fullscreenIndex]}
						nextSrc={pictures[(fullscreenIndex + 1) % pictures.length]}
						prevSrc={pictures[(fullscreenIndex + pictures.length - 1) % pictures.length]}
						onCloseRequest={toggleFullscreenGallery}
						onMovePrevRequest={() => setFullscreenIndex((fullscreenIndex + pictures.length - 1) % pictures.length)}
						onMoveNextRequest={() => setFullscreenIndex((fullscreenIndex + 1) % pictures.length)}
					/>
				)
			}
		</>
	);
}

const CustomBikes = () => (
	<Layout>
		<SEO
			title="Customs"
			description="Retrouvez toutes les réalisations de préparations et de modifications des motos de nos clients."
			lang="fr"
			url="/customs"
		/>

		<div className="box-container first">
			<div className="content-block">
				<div className="content-block-bg" />
				<h2>CUSTOMS</h2>

				<Tabs defaultActiveKey="0">
					{categories.map((item, key) => (
						<Tabs.TabPane tab={item} key={key}>
							<CustomGallery category={key} />
						</Tabs.TabPane>
					))}
				</Tabs>
			</div>
		</div>
	</Layout>
);

export default CustomBikes;
